import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store'
import "./Login.scss"
import { loginThunk } from 'store/reducers/Login/thrunks/loginThunk';
import nameOf from 'utility/nameOf';
import ButtonFno from 'components/inputs/ButtonFno';

interface ILoginForm{
    email: string;
    password: string;
}

const Login: React.FC = () => {

    const { t } = useTranslation(nameOf({Login}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const loginErrors = useAppSelector(state => state.login.errors);
    const loading = useAppSelector(state => state.login.loading);
    const showSessionExpiredModal = useAppSelector(state => state.login.showSessionExpiredModal);

    useEffect(() => {
        document.title = t('Se connecter');
    }, [])
    
    const onSubmit = async (values: ILoginForm, {setFieldError}:FormikHelpers<ILoginForm>) => {
        dispatch(loginThunk(values.email, values.password))
    };
    
    const schema = yup.object().shape({
        email: yup.string().required(t("Champ obligatoire") as string).email(t("Veuillez entrer une adresse email valide") as string),
        password: yup.string().required(t("Champ obligatoire") as string).min(8, t("Votre mot de passe doit contenir au moins 8 caractères") as string)
      });

    return (
        <div style={{ position: 'relative', width: '100%', height:"100vh", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundImage: "url(/images/login-background.jpg)", backgroundSize: "cover"}}>
            <img src='/logo.png' />
           
            <div className='login-panel' style={{minWidth: "350px", marginTop:'30px'}}>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                        email: '',
                        password: '',
                    } as ILoginForm}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit} style={{width:"100%"}}>
                            <Modal.Dialog >
                                <Modal.Header className='header'>
                                    <Modal.Title>{t("Se connecter")}</Modal.Title>
                                </Modal.Header>

                                <Modal.Body className='body'>
                                {showSessionExpiredModal && <Alert variant={"warning"}>
                                    <FontAwesomeIcon className='me-2' icon={["fas", "exclamation"]} /> {t("Votre session a expirée, reconnectez-vous")}
                                 </Alert>}

                                    <fieldset disabled={isSubmitting || loading}>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label>{t("Email")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={touched.email && !!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId="validationFormik04">
                                            <Form.Label>{t("Mot de passe")}</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isValid={touched.password && !errors.password}
                                                isInvalid={touched.password && !!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {loginErrors &&  <Alert variant="danger">
                                            {t("Mail ou mot de passe invalide")}
                                        </Alert>}

                                    </fieldset>
                                    <div className="d-grid gap-2">
                                        <ButtonFno type="submit">
                                            {isSubmitting || loading ? <Spinner as="span" size="sm" role="status" animation="border" /> : <></> } {t("Connexion")}
                                        </ButtonFno>

                                        {/*<Button type="submit" variant='link' disabled={isSubmitting || loading} className='text-blue'>
                                            {t("Mot de passe oublié")}
                                        </Button>*/}
                                    </div>
                                  
                                </Modal.Body>

                            </Modal.Dialog>
                        </Form>
                        )}
                </Formik>
            </div>
        </div>
    )
}

export default Login
