import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientDetails, MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import { ClientItem, MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { PathDetailsModelPagedListResult, RequestClientDetails, RequestClientDetailsPagedListResult, RequestRawProductDetailsPagedListResult, StatisticsByDay } from "backend/ApiMiddlewareDefinition/data-contracts";
import dayjs from "dayjs";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IMiddlewarePickingStore {
  middlewares: MiddlewareApiDetails[];
  middlewareSelected: MiddlewareApiDetails | undefined;
  globalStatisticsByDay: StatisticsByDay[];
  loading: boolean;
  statsDateFrom: Date;
  statsDateTo: Date;

  missions: PathDetailsModelPagedListResult | undefined;
  rawProducts: RequestRawProductDetailsPagedListResult | undefined;
}

const initialState: IMiddlewarePickingStore = {
  middlewareSelected: undefined,
  middlewares:[],
  globalStatisticsByDay: [],
  loading: false,
  statsDateFrom: dayjs().add(-6, 'day').toDate(),
  statsDateTo: dayjs().toDate(),

  missions: undefined,
  rawProducts: undefined
};

const middlewarePickingSlice = createSlice({
  name: "middlewareRequestSlice",
  initialState,
  reducers: {
    setMiddlewares: (state, action: PayloadAction<MiddlewareApiDetails[]>) => {
      state.middlewares = action.payload;
    },
    setMiddlewareSelected: (state, action: PayloadAction<MiddlewareApiDetails | undefined>) => {
      state.middlewareSelected = action.payload;
    },
    setGlobalStatisticsByDay: (state, action: PayloadAction<StatisticsByDay[]>) => {
      state.globalStatisticsByDay = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStatsDates: (state, action: PayloadAction<{from: Date, to: Date}>) => {
      state.statsDateFrom = action.payload.from;
      state.statsDateTo = action.payload.to;
    },
    setMissions: (state, action: PayloadAction<PathDetailsModelPagedListResult | undefined>) => {
      state.missions = action.payload;
    },
    setRawProducts: (state, action: PayloadAction<RequestRawProductDetailsPagedListResult | undefined>) => {
      state.rawProducts = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.middlewareSelected = undefined;
      state.middlewares = [];
      state.loading = false;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: middlewarePickingActions,
  reducer: middlewarePickingReducer,
} = middlewarePickingSlice;
