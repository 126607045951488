import React, { useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import "./AuditBatching.scss";
import { useNavigate } from 'react-router-dom';
import computeGainPourcent from 'utility/computeGainPourcent';
import StatisticCard from 'components/StatisticCard';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';


interface IRowType{
    missionRef: string;
    supports: string[];
    searchField: string;
    fillingRate: number;
}

const AuditBatching: React.FC = () => {
    const { t } = useTranslation(nameOf({PickingOrder: AuditBatching}), { useSuspense: false});
    const navigate = useNavigate();
    
    const [selectedClientRow, setSelectedClientRow] = useState<IRowType | undefined>();

    const [rowsClient, setRowsClient] = useState<IRowType[]>([]);
    const [rowsFno, setRowsFno] = useState<IRowType[]>([]);

    const [orderHovered, setOrderHovered] = useState<string | undefined>();

    const [headers, setHeaders] = useState< TableColumn<any>[]>([]);

    const loadingOptimizationResult = useAppSelector(state => state.audit.loadingOptimizationResult);

    const [searchClientTerm, setSearchClientTerm] = useState('');
    const [searchFnoTerm, setSearchFnoTerm] = useState('');

    const auditData = useAppSelector(state => state.audit.data);
    const auditColumn = useAppSelector(state => state.audit.auditRawFileSelected?.columnsBindingData);
    const auditOptimizationResults = useAppSelector(state => state.audit.optimizationResult);
    const currentMap = useAppSelector(state => state.map.currentMap);

    useEffect(() => {
        document.title = t("Regroupement de commandes");
    }, [])

    useEffect(() => {
        if(!checkPermissions(PermissionsEnum.AuditList))
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [currentMap])

    useEffect(() => {
        const headersData: TableColumn<IRowType>[] = []

        headersData.push({
            id:"mission",
            name: t("Ref. mission"),
            sortable: true,
            selector: row => row.missionRef
        })

        headersData.push({
            id:"fillingRate",
            name: "Remplissage (%)",
            sortable: true,
            selector: row => {
                return row.fillingRate + "%";
            }
        })

        headersData.push({
            id:"products",
            name: "Nb. support",
            sortable: true,
            selector: row => {
                return row.supports.length;
            }
        })
        
        setHeaders(headersData)
    }, [])


    useEffect(() => {
        if(auditOptimizationResults)
        {

            setRowsFno(
                _.map(auditOptimizationResults.batching.optimized, (values, key) => {
                return {
                     supports: values,
                     missionRef: key,
                     searchField: key + " " +_.join(values, " ").toLocaleLowerCase(),
                     fillingRate: auditOptimizationResults.batchingPathClient.missions[key]?.fillingRate ?? 0
                } as IRowType
            }));

            setRowsClient(
                _.map(auditOptimizationResults.batching.original, (values, key) => {
                return {
                     supports: values,
                     missionRef: key,
                     searchField:  key + " " + _.join(values, " ").toLocaleLowerCase(),
                     fillingRate: auditOptimizationResults.path.paths[key]?.fillingRate ?? 0
                } as IRowType
            }));
        }
        else
        {
            setRowsFno([]);
            setRowsClient([]);
        }
    }, [auditOptimizationResults])

    const onSelectClientRow = (rowClient: IRowType | undefined) => {
        setSelectedClientRow(rowClient);
    }


    let rowsClientSearch = rowsClient
    //let rowsFnoSearch = rowsFno
    let rowsFnoSearch =  _.orderBy(rowsFno, [entity => entity.fillingRate], ['desc']);
    if(selectedClientRow && auditColumn && auditData)
    {
        rowsFnoSearch = rowsFno.filter(rowFno => {
            return rowFno.supports.some(fnoSupport => _.some(selectedClientRow.supports, (SuppRef) => SuppRef ==  fnoSupport))
        })
    }

    const rowClientDetailsRender: React.FC<ExpanderComponentProps<IRowType>> = ({ data }) => {
        return <>
            {data.supports.map((supportRef, i) => {
                return <Badge 
                    key={i}
                    bg={orderHovered && supportRef == orderHovered  ? "danger" : "primary"}
                    onMouseEnter={() => setOrderHovered(supportRef)} 
                    onMouseLeave={() => setOrderHovered(undefined)} 
                    className='m-2'>
                    {supportRef}</Badge>;
                })
            }
        </>;
    };

    const rowFnoDetailsRender: React.FC<ExpanderComponentProps<IRowType>> = ({ data }) => {
        return <>
            {data.supports.map((supportFno) => {

                let badgeColor = "secondary";
                if(selectedClientRow?.supports.some((o) => o == supportFno)) badgeColor = "primary";
                if(orderHovered && orderHovered == supportFno)badgeColor = "danger";


                return <Badge key={supportFno} className='m-2' bg={badgeColor}>{supportFno}</Badge>;
            })}
        </>;
    };

    if(searchClientTerm)
    {
        rowsClientSearch = rowsClientSearch.filter((entity) => {
            return entity.searchField.includes(searchClientTerm.toLocaleLowerCase());
        });
    }

    if(searchFnoTerm)
    {
        rowsFnoSearch = rowsFnoSearch.filter((entity) => {
            return entity.searchField.includes(searchFnoTerm.toLocaleLowerCase());
        });

        
    }

    const totalDistanceOriginal = auditOptimizationResults?.batching.totalDistanceOriginal ?? undefined;
    const totalDistanceOptimized = auditOptimizationResults?.batching.totalDistanceOptimized ?? undefined;

    const gainTotal = ((totalDistanceOriginal ?? 0) - (totalDistanceOptimized ?? 0)) / 1000;
    const gainPourcentString = computeGainPourcent(totalDistanceOriginal ?? 0, totalDistanceOptimized ?? 0);

    const clientFillingRatePourcent = _.sum(_.map(rowsClient, (row) => row.fillingRate)) / rowsClient.length;
    const fnoFillingRatePourcent = _.sum(_.map(rowsFno, (row) => row.fillingRate)) / rowsFno.length;
    
    return (
        <div id="audit-batching-page">
             <PageHeader title={t("Regroupement commandes")} middlePart={[{title:t("Audit"), to:ROUTES.AUDIT_BATCHING}]}/>
             {gainTotal > 0 && <Row>
                <Col><StatisticCard title={t("Gain moyen")} color='orange' value={gainPourcentString.toLocaleString("fr-FR") + "%"} icon='gears'   loading={loadingOptimizationResult}/></Col>
                <Col><StatisticCard title={t("Gain (km)")} color='blue' value={gainTotal.toLocaleString("fr-FR")} icon='route'   loading={loadingOptimizationResult}/></Col>
             </Row>}
            <Row id="content-table">
                <Col>
                    <div className='table-component'>
                        <DataTable 
                            className={loadingOptimizationResult ? "loading": ""}
                            dense
                            columns={headers} 
                            paginationPerPage={30}
                            data={rowsClientSearch} 
                            progressPending={loadingOptimizationResult}
                            progressComponent={<Loading text={t("Chargement")}/>}
                            striped={true}
                            subHeader={true}
                            highlightOnHover={true}
                            pagination
                            expandableRows
                            expandableRowsComponent={rowClientDetailsRender}
                            expandableRowExpanded={(row: IRowType) => row.missionRef == selectedClientRow?.missionRef}
                            expandOnRowClicked={true}
                            onRowExpandToggled={(expanded, row) => onSelectClientRow(expanded ? row: undefined)}
                            noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune data")}</div>}
                            subHeaderComponent={<> 
                                <h5 className='text-uppercase'> {t("Regroupement client")} 
                                    <Badge bg="primary" className='text-lowercase ms-2'>{rowsClientSearch.length.toLocaleString("fr-FR") + " " + t("missions")}</Badge> 
                                    {totalDistanceOriginal && <Badge bg="orange" className='text-lowercase ms-2'>{(totalDistanceOriginal / 1000).toLocaleString("fr-FR") + " " + t("km")}</Badge> }
                                    {!isNaN(clientFillingRatePourcent) &&  clientFillingRatePourcent > 0  && <Badge bg="info" className='text-capitalize ms-2'>{t("Remplissage") + " "+ clientFillingRatePourcent.toLocaleString("fr-FR") + "%"}</Badge> }
                                    
                                    {loadingOptimizationResult && <Loading inline size={20} />}
                                </h5>
                                <div className='search-panel'>
                                    <input placeholder={t("Rechercher")} onChange={(e) => setSearchClientTerm(e.target.value.toLowerCase())}/>
                                </div>
                                </>}/>
                    </div>
                </Col>
                <Col>
                    <div className='table-component'>
                        <DataTable 
                            className={loadingOptimizationResult ? "loading": ""}
                            dense
                            columns={headers}
                            paginationPerPage={30}
                            data={rowsFnoSearch} 
                            progressPending={loadingOptimizationResult}
                            progressComponent={<Loading text={t("Calcul de l'optimisation du regroupement")}/>}
                            striped={true}
                            subHeader={true}
                            highlightOnHover={true}
                            pagination
                            expandableRows
                            expandableRowsComponent={rowFnoDetailsRender}
                            expandableRowExpanded={(row: IRowType) => {
                                if(selectedClientRow && orderHovered)
                                {
                                    return row.supports.some((m) => m == orderHovered)
                                }
                                return !!selectedClientRow;
                            }}
                            noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune data")}</div>}
                            subHeaderComponent={<> 
                                <h5 className='text-uppercase'> 
                                    {!selectedClientRow && <>
                                        {t("Regroupement FnO")} 
                                        <Badge bg="primary" className='text-lowercase ms-2'>{rowsFnoSearch.length.toLocaleString("fr-FR") + " " + t("missions")}</Badge>
                                        {totalDistanceOptimized && <Badge bg="orange" className='text-lowercase ms-2'>{(totalDistanceOptimized / 1000).toLocaleString("fr-FR") + " " + t("km")}</Badge> } 
                                        {!isNaN(fnoFillingRatePourcent) && fnoFillingRatePourcent > 0  && <Badge bg="info" className='text-capitalize ms-2'>{t("Remplissage") + " "+fnoFillingRatePourcent.toLocaleString("fr-FR") + "%"}</Badge> }
                                    
                                    </>}
                                    
                                    {loadingOptimizationResult && <Loading inline size={20} />}
                                    {selectedClientRow && t("Regroupement FnO pour") + " " + selectedClientRow.missionRef}
                                </h5>
                                <div className='search-panel'>
                                    <input placeholder={t("Rechercher")} onChange={(e) => setSearchFnoTerm(e.target.value.toLowerCase())}/>
                                </div>
                                </>}/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AuditBatching
