import PageHeader from 'components/PageHeader';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Badge, Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/store';
import ButtonFno from 'components/inputs/ButtonFno';
import UploadFileForm from 'components/Admin/forms/UploadFileForm';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import MiddlewareSelector from 'components/Admin/MiddlewareRequest/MiddlewareSelector';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import { adminMiddlewareRequestActions } from 'store/reducers/Admin/MiddlewareRequests/middlewareRequestSlice';
import apiMiddlewareDefinition from 'backend/apiMiddlewareDefinition';
import { RequestClientModel } from 'backend/ApiMiddlewareDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { TableColumn } from 'react-data-table-component';
import MapSelector from 'components/selectors/MapSelector';

interface QueryResult{
    id: string;
    index: number;
    requestBody: any;
    response: RequestClientModel;
    duration: number;
    error: any;
}



const IntermarcheTestMiddleware: React.FC = () => {
    const { mapId } = useParams();
   const dispatch = useAppDispatch();
   const {
    selectedMiddleware,
    selectedMap,
} = useAppSelector(state => state.adminMiddlewareRequest);
    
    const selectedAppClient = useAppSelector(state => state.userProfil.currentTenant);
    const allMiddlewares = useAppSelector(state => state.adminRealtimeMiddleware.middlewares.filter(m => m.clientTenant == selectedAppClient?.tenant));
    const allMaps = useAppSelector(state => state.adminClientMap.maps);
   const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);
   const [requestBodies, setRequestBodies] = useState<any[]>([]);

   const [queryResponses, setQueryResponses] = useState<QueryResult[]>([]);
   const _queryResponses = useRef<QueryResult[]>([]);


   useEffect(() => {
    if(allMiddlewares.length > 0 && !selectedMiddleware)
    {
        dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(allMiddlewares[0]));
    }

    if(allMiddlewares.length == 0 && selectedMiddleware)
    {
        dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(undefined));
    }
}, [allMiddlewares])


   useEffect(() => {
        if(fileSelected)
        {
            Papa.parse<any[]>(fileSelected, {
                download: true,
                worker: true,
                header: true,
                error(error) {
                    toast.error("Impossible de lire et d'extraire le contenu du fichier CSV")
                },
                complete(results) {

                    const fields = results.meta.fields;
                    const rows = results.data.filter(m => m != undefined);

                    const missions = _.groupBy(rows, "numero_commande");
                    const requestBody = _.map(missions, (value, key) => {
                        return {
                            storeId: mapId,
                            deviceId: "test",
                            sessionId: key,
                            articles: _.sortBy(value.map((product) => {
                                return {
                                    ean: _.get(product, "ean"),
                                    address: _.get(product, "adresse"),
                                    address_formatted: _.get(product, "adresse") === "NULL" ? "" :  _.padStart(_.get(product, "adresse"), 6, "0"),
                                    productFamily: _.get(product, "circuit"),
                                    quantityToPrepare: _.get(product, "quantite_commandee"),
                                    isPromotional: false,
                                    mission: key
                                }
                            }), "address_formatted")
                        }
                    })
                    setRequestBodies(requestBody);
                },
            });
        }
   }, [fileSelected])


   const onMapChange = (map: MapDetails) => {
        dispatch(adminMiddlewareRequestActions.setSelectedMap(map))
    }

    const onMiddlewareChange = (middleware: MiddlewareApiDetails) => {
        dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(middleware))
    }

    const resetAll = () => {
        setRequestBodies([]);
        setQueryResponses([]);
        _queryResponses.current=[];
        setFileSelected(undefined);
    }

    const startRequests = async () => {
        _queryResponses.current=[];
        if(selectedMiddleware && selectedAppClient && selectedMap)
        {
            const apiDefinition = apiMiddlewareDefinition(selectedMiddleware?.baseUrl, selectedAppClient.tenant)
        
            for (let index = 0; index < requestBodies.length; index++) {
                const start = performance.now();
                const result = await apiDefinition.OptimizationItm.optimizationRunPost(selectedMap.id, requestBodies[index])
                    .catch((error) => {
                        return {
                            data: {
                                articles: [],
                                isError: true,
                                isOptimized: false,
                            } as RequestClientModel,
                            error: error
                        };
                    });
                const end = performance.now();
                const responseTime = end - start;

                const newResponse = {
                    id: (index + 1).toString(),
                    index: index,
                    requestBody: requestBodies[index],
                    response: result.data,
                    duration: responseTime,
                    error: result.error
                };
                
                setQueryResponses([
                    newResponse,
                    ..._queryResponses.current
                ])
                _queryResponses.current.unshift(newResponse);
            }
        }
    }
    
    const columns: TableColumn<QueryResult>[] = [
        {
            id:'mission',
            name: 'mission',
            selector: row => row.response.sessionId ?? "No session",
        },
        {
            id:'nbProduct',
            name: 'Nb produits',
            sortable: true,
            selector: row => row.response.articles?.length ?? 0,
        },
        {
            id:'duration',
            name: 'Temps réponse (ms)',
            selector: row => row.duration,
            sortable: true,
            cell: (row, index, column, id) =>{
                return <Badge bg={row.duration > 2000 ? "danger": "success"}>{row.duration.toFixed(2)} ms</Badge>   
            }
        },
        {
            id:'optimized',
            name: 'Optimisé',
            sortable: true,
            selector: row => row.response.isOptimized == true,
            cell: (row, index, column, id) =>{
                return <Badge bg={row.response.isOptimized == false ? "warning": "success"}>{row.response.isOptimized == false ? "Non" : "Oui"}</Badge>   
            }
        },
        {
            id:'error',
            name: 'Erreur',
            sortable: true,
            selector: row => row.response.isError == true,
            cell: (row, index, column, id) =>{
                return <Badge bg={row.response.isError == true ? "danger": "success"}>{row.response.isError == true ? "Erreur" : "Ok"}</Badge>   
            }
        },
       
    ];


    
    const totalRequest = requestBodies.length;

    const totalSuccess =    _.filter(queryResponses, (m) => m.response.isOptimized && !m.response.isError).length 
    const totalWarning =    _.filter(queryResponses, (m) => !m.response.isOptimized && !m.response.isError).length
    const totalError =      _.filter(queryResponses, (m) => !m.response.isOptimized && m.response.isError).length 

    const totalSuccessPourcent =    (totalSuccess / totalRequest ) * 100
    const totalWarningPourcent =    (totalWarning / totalRequest ) * 100
    const totalErrorPourcent =      (totalError/ totalRequest ) * 100

    const responseTimeAverage = _.mean(_.map(queryResponses, (m) => m.duration))

   return (
        <div>
            {<PageHeader title={'Test du middleware intermarché'}/>}
            <Container  fluid>
                <Row>
                    <Col>
                        <MiddlewareSelector onMiddlewareChange={onMiddlewareChange} middlewareIdSelected={selectedMiddleware?.id}/>
                    </Col>
                    <Col>
                        <MapSelector 
                            allMaps={allMaps} 
                            currentMap={selectedMap} 
                            isClearable
                            onMapChange={onMapChange}/>
                    </Col>
                </Row>
             </Container>
            {!fileSelected && <Row style={{height: "100%"}}>
                <Col sm="12">
                    <UploadFileForm  loading={false}   onSubmit={(files) => setFileSelected(files[0])}/>
                </Col>
            </Row>}
            {fileSelected && <Row>
                <Col sm="12" className='text-center'>
                    <div>Nombre de requêtes: {totalRequest}</div>
                    <ButtonFno disabled={!selectedMap} color={!selectedMap ? "gray" : "blue"} onClick={startRequests}>{!selectedMap ? "Sélectionner une map ..." : "Envoyer les requêtes"}</ButtonFno>
                    <ButtonFno className='ms-2' color={"orange"} onClick={resetAll}>{"Annuler"}</ButtonFno>
                </Col>
            </Row>}

            {fileSelected && <Row>
                <Col sm="12" >
                    <ProgressBar className='m-3' max={totalRequest} style={{height: "35px"}}>
                        <ProgressBar animated label={totalSuccessPourcent.toFixed(2) + "%"} variant="success" now={totalSuccessPourcent} key={1} />
                        <ProgressBar animated label={totalWarningPourcent.toFixed(2) + "%"} variant="warning" now={totalWarningPourcent} key={2} />
                        <ProgressBar animated label={totalErrorPourcent.toFixed(2) + "%"} variant="danger" now={totalErrorPourcent} key={3} />
                    </ProgressBar>
                </Col>
                <Col sm="12" >
                    <Row className='text-center'>
                        <Col className='fs-4'><Badge bg="primary">Total: {totalRequest} requêtes</Badge></Col>
                        <Col className='fs-4'><Badge bg="secondary">Temps réponse: {responseTimeAverage ? responseTimeAverage.toFixed(2) : "0"} ms</Badge></Col>
                        <Col className='fs-4'><Badge bg="success">Optimisé: {totalSuccess} requêtes</Badge></Col>
                        <Col className='fs-4'><Badge bg="warning">Non optimisé: {totalWarning} requêtes</Badge></Col>
                        <Col className='fs-4'><Badge bg="danger">Erreur: {totalError} requêtes</Badge></Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <TableWithCrudComponent 
                        entities={queryResponses} 
                        columns={columns}
                        hideIdColumn
                        addDefaultActionsColumn={false}
                        defaultSortFieldId={"index"}
                        serverSide={false}
                        loadingList={false}
                        loadingForm={false}
                        fieldSearchable={(entity) => {
                            return [
                                entity.response.sessionId ?? "",
                            ]
                        }}
                        translations={{
                            tableTitle: "Requêtes",
                            noEntityText: "Aucune requête",
                            createTitle:  "",
                            createButtonText: "",
                            deleteText: (entity) => "",
                            deleteTitle: (entity) =>  "",
                            updateText: (entity) => "",
                        }}
                />
                </Col>
            </Row>}
        </div>
    )
}
export default IntermarcheTestMiddleware;