import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import Loading from 'components/Loading';
import { Accordion, Badge, Button, Col, Form, Row } from 'react-bootstrap';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import "./MiddlewarePathCompare.scss";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import PickingRequestPathsDetails from 'components/Picking/PickingRequestPathsDetails';
import { loadMissionsThrunk } from 'store/reducers/MiddlewarePicking/thrunks/loadMissionsThrunk';
import MiddlewarePathCompareRow from './MiddlewarePathCompareRow';
import FloorControls from 'components/mapControls/FloorControls';
import AssetTooltip from 'components/mapControls/AssetTooltip';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';

export interface IOrderRow{
    ref: string;
    products: {
        ean: string;
        emplacement: string;
        quantity: string;
    }[];
    searchField: string;
}



const RequestPathCompare: React.FC = () => {
    const { t } = useTranslation(nameOf({RequestPathCompare}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const [missionSearchTerm, setMissionSearchTerm] = useState("");

    const moduleMiddlewareEnabled = useAppSelector(state => state.middlewarePicking.middlewareSelected != null);
    const currentMap = useAppSelector(state => state.map.currentMap);
    const {
        loading: loadingPicking,
        middlewareSelected,
        statsDateFrom,
        statsDateTo,
        missions
    } = useAppSelector(state => state.middlewarePicking);

    const {
        sdk,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    

    useEffect(() => {
        if(!moduleMiddlewareEnabled)
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [currentMap, middlewareSelected])



    useEffect(() => {

        if(middlewareSelected && currentMap){
            loadMissions(1, missionSearchTerm)
        }
        
    }, [middlewareSelected, currentMap, statsDateFrom, statsDateTo])

    const loadMissions = (page: number, missionTerm: string) => {
        
        if(middlewareSelected && currentMap){
            dispatch(loadMissionsThrunk(middlewareSelected.baseUrl, currentMap?.id, statsDateFrom, statsDateTo, page, 10, missionTerm))
        }
    }

    const loadMissionsDebounced = useMemo(() => {
        return _.debounce(loadMissions, 700);
      }, []);


    useEffect(() => {
        loadMissionsDebounced(1, missionSearchTerm);
    }, [missionSearchTerm])


    return (
        <div id="statistic-request-optimization-path">
             {<PageHeader title={t('Digital twin')} middlePart={[{title:t("Picking"), to:ROUTES.PICKING_DASHBOARD}]}/>}
             <Row style={{height: "100%"}}>
                <Col id="col-map-container" sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && <div>
                        <h4>
                            {t("Missions")} ({missions?.totalCount ?? 0})
                            {/*<Badge  className='ms-2 fs-6' bg="orange">{`${t("Gain moyen")} ${gainPourcentString}%`}</Badge>*/}
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.user.moveCameraToUserPosition()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        <div className='search-panel mt-1 mb-1'>
                                <Form.Control
                                    type="text"
                                    name="missionSearchTerm"
                                    placeholder={t('Rechercher une mission...')}
                                    value={missionSearchTerm}
                                    onChange={(e) => setMissionSearchTerm(e.target.value)}
                                />
                        </div>
                        <div>
                            {sdk && <MiddlewarePathCompareRow 
                                        sdk={sdk} 
                                        loading={loadingPicking}
                                        pathDetailsClients={missions?.items ?? []}
                                        onChangePage={(page) => loadMissions(page, missionSearchTerm)}/>}
                        </div>
                    </div>}
                    {/*loadingPicking && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>*/}
                </Col>
             </Row>
        </div>
    )
}

export default RequestPathCompare
