import dayjs from 'dayjs';
import Sdk from 'fno-sdk';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/store';
import nameOf from 'utility/nameOf';

type Props = {
    sdk: Sdk;
  };


const HeatMapPanel: React.FC<Props> = ({ sdk}) => {
   const { t } = useTranslation(nameOf({HeatMapPanel}), { useSuspense: false});
   const dispatch = useAppDispatch();

   useEffect(() => {
    sdk.heatmap.displayHeatmap(dayjs().add(-2, 'month').toDate(), dayjs().toDate(), "Optimized").then((data) => {
        console.log("AAA terminé !!!!", data)
    })
    return () => {
        sdk.heatmap.displayHeatmap(dayjs().toDate(), dayjs().toDate(), "Optimized")
    }
   }, [])

   return (
       <div>
           Page HeatMapPanel
       </div>
   )
}
export default HeatMapPanel