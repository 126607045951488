import i18next from "i18next";
import { toast } from "react-toastify";
import {middlewarePickingActions} from '../middlewarePickingSlice';
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import dayjs from "dayjs";
import { PathDetailsBatchField } from "backend/ApiMiddlewareDefinition/data-contracts";


export const loadMissionsThrunk = (
        baseUrl: string, 
        mapId: string, 
        from: Date, 
        to: Date,
        page: number,
        pageSize = 10,
        mission?: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant
        dispatch(middlewarePickingActions.setLoading(true));

        //remove timeoffset
        const fromParam = dayjs(from).add(-from.getTimezoneOffset() ,"minutes").toISOString();
        const toParam = dayjs(to).add(-to.getTimezoneOffset() ,"minutes").add(1, "day").toISOString();

        const pathsDetails = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .requests.searchPathsDetails({
                                    mapId: mapId,
                                    from: fromParam,
                                    to: toParam,
                                    type: PathDetailsBatchField.ClientPathDetails,
                                    pageSize: pageSize,
                                    page: page,
                                    mission: mission
                                  });

       dispatch(middlewarePickingActions.setMissions(pathsDetails.data));

      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des requêtes d'optimisation"));
          dispatch(middlewarePickingActions.setMissions(undefined));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(middlewarePickingActions.setLoading(false));
      }
    };
  };