import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import SelectLarge from 'components/inputs/SelectLarge';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';



interface OwnProps {
    onMiddlewareChange: (middleware: MiddlewareApiDetails) => void;
    middlewareIdSelected?: string;
}



/**
 * MiddlewareSelector is a React functional component that displays a dropdown
 * selector list of all the middlewares associated with the current application
 * client.
 * 
 * @param {OwnProps} props - the properties for this component
 * @param {OwnProps["onMiddlewareChange"]} props.onMiddlewareChange - a function
 * that is called when a middleware is selected, and passes the selected middleware
 * as an argument
 * @param {OwnProps["middlewareIdSelected"]} [props.middlewareIdSelected] - the
 * id of the middleware that is currently selected, used to initialize the
 * dropdown (default: undefined)
 * @returns {React.ReactElement} - the rendered component
 */
const MiddlewareSelector: React.FC<OwnProps> = ({onMiddlewareChange, middlewareIdSelected}) => {
   const { t } = useTranslation(nameOf({MiddlewareSelector}), { useSuspense: false});
   const selectedAppClient = useAppSelector(state => state.userProfil.currentTenant);
   const allMiddlewares = useAppSelector(state => state.adminRealtimeMiddleware.middlewares.filter(m => m.clientTenant == selectedAppClient?.tenant));
    const selected = allMiddlewares.find(m => m.id.toString() == middlewareIdSelected)

   useEffect(() => {
   }, [])

   return (<SelectLarge options={allMiddlewares} 
        value={selected} 
        noOption={t("Aucun middleware pour ce client")}
        placeholder={t("Sélectionner un middleware ...")}
        onChange={onMiddlewareChange} 
        valueRender={(middleware) => <div className='text-truncate text-uppercase'> 
            <div><FontAwesomeIcon className='me-2' icon={["fas", "server"]} />  {middleware.name}</div><div><small>{middleware.baseUrl}</small></div>
            </div>}
        optionRender={(middleware) => <div className='text-truncate text-uppercase'> 
        <div><FontAwesomeIcon className='me-2' icon={["fas", "server"]} />  {middleware.name}</div><div><small>{middleware.baseUrl}</small></div>
    </div>}/>)
}
export default MiddlewareSelector