import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import Switch from "react-switch";
import _ from 'lodash';
import ButtonFno from 'components/inputs/ButtonFno';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { ROUTES } from 'resources/routes-constants';
import { updateMapMaintenanceThunk } from 'store/reducers/Admin/ClientMap/thrunks/updateMapMaintenanceThunk';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import copyToClipboard from 'utility/copyToClipboard';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import { deleteClientMapThrunk } from 'store/reducers/Admin/ClientMap/thrunks/deleteClientMapThrunk';
import { toast } from 'react-toastify';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';

const AdminClientMaps: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(nameOf({AdminClientMaps}), { useSuspense: false});
    const navigate = useNavigate();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const {
        maps,
        loadingForm, 
        loadingList
    } =  useAppSelector(state => state.adminClientMap);
    const [searchTerm, setSearchTerm] = useState('');
    const [entityToDelete, setEntityToDelete] = useState<MapDetails | undefined>();
    
    const { clientId } = useParams();
    

    useEffect(() => {
        document.title = t('[Admin] Lieux');
    }, [])

    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }


    const onDeleteEntity = (mapToDelete: MapDetails) => {

        dispatch(deleteClientMapThrunk(mapToDelete.id, {
            onSuccess: (success) => {
                setEntityToDelete(undefined);
                toast.success(t("La map a bien été supprimée"));
            },
            onError: (error) => {
                parseApiErrorSetFieldError(error, (field, msg) => toast.error(`${field}: ${t(msg ?? "", { ns:"TransverseError"})}`));
            }
        }))
    }
    
    const columns: TableColumn<MapDetails>[] = [
        {
            id:'id',
            name: t('id'),
            sortable: true,
            width:"230px",
            selector: row => row.idShort,
            cell: row => <div>
            <small>{row.id}</small>
                <Button size='sm' variant='link' onClick={() => copyToClipboard(row.id, t("L'id de la map a bien été copié"))} title={t("copier l'id")}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
            </div>
        },
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name
        },
        {
            id:'polygons',
            name: t('Assets'),
            sortable: true,
            selector: row => row.totalAssets,
        },
        {
            id:'buildings',
            name: t('Bâtiments'),
            sortable: true,
            selector: row => row.totalBuildings,
        },
        {
            id:'floors',
            name: t('Etages'),
            sortable: true,
            selector: row => row.totalFloors,
        },
        /*{
            id:'templates',
            name: t('Templates'),
            sortable: true,
            selector: row => row.totalTemplates,
        },
        {
            id:'markers',
            name: t('Markers'),
            sortable: true,
            selector: row => row.totalMarkers,
        },
        {
            id:'zones',
            name: t('Zones'),
            sortable: true,
            selector: row => row.totalZones,
        },*/
        {
            id:'audits',
            name: t('Audits'),
            sortable: true,
            selector: row => row.totalAudits,
        },
        /*{
            id:'markerTemplates',
            name: t('Marker template'),
            sortable: true,
            selector: row => row.totalMarkerTemplates,
        },*/
        {
            id:'optimizationSettingId',
            name: t('Matrice'),
            sortable: true,
            selector: row => row.optimizationSettingId ?? "",
            cell: row => row.optimizationSettingId ? <><FontAwesomeIcon className='me-2' icon={["fas", "check"]} /> {`(v${row.optimizationSettingId})`}</> : <></>
        },
        {
            id:'mapVersion',
            name: t('Version carte'),
            sortable: true,
            selector: row => row.version,
        },
        {
            id:'maintenance',
            width:"150px",
            center:true,
            name: <div className='text-center'>{t('Mode maintenance')}</div>,
            sortable: true,
            selector: row => row.isUnderMaintenance,
            cell: row => <div>
                 <Switch checked={row.isUnderMaintenance} onChange={(enable) => {
                    dispatch(updateMapMaintenanceThunk(row, enable));
                }}/>
            </div>,
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div>
                    <Button size='sm' variant='success' onClick={() => navigate(ROUTES.ADMIN.CLIENT(selectedClient.id.toString()).MAP_DETAILS(row.id.toString()))}>
                        <FontAwesomeIcon icon={["fas", "edit"]} />
                    </Button>
                    <Button size='sm' className='ms-1' variant='danger' onClick={() => setEntityToDelete(row)}>
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                    </Button>
                </div>
            }
        }
    ];


    const placesFiltered = searchTerm ? maps.filter((place) => {
        const termsToCheck = [
            place.name,
            place.reference,
            //place.mapVersion
        ];

        const joinTerm = _.join(termsToCheck, ' ').toLocaleLowerCase();

        return joinTerm.includes(searchTerm);
    }): maps;
    



    return (
        <div>
             <PageHeader title={t("Lieux")} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Client")},
                {to: ROUTES.ADMIN.CLIENT(clientId).DETAILS, title: selectedClient.name}]}/>

            <div>
                {t("Lieux de")} {selectedClient?.name}
                <div className='table-component'>
               
                    <DataTable 
                        columns={columns} 
                        data={placesFiltered} 
                        
                        progressPending={loadingList}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        defaultSortFieldId={'id'} 
                        defaultSortAsc={false} 
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun lieu disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Mes lieux") + " ("+maps.length+")"} {loadingList && <Loading inline size={20}/>}</h5>
                            <div className='search-panel'>
                                <ButtonFno sm className='me-2' onClick={() => navigate(ROUTES.ADMIN.CLIENT(selectedClient.id.toString()).MAP_CREATE)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer un bâtiment")}</ButtonFno>
                                <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            
                            </>}
                        sortServer={false}/>
                </div>
            </div>
            {entityToDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setEntityToDelete(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Supprimer la carte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={() => setEntityToDelete(undefined)} 
                                        onSubmit={() => onDeleteEntity(entityToDelete)} 
                                        text={<div>
                                            <div>{t("Êtes vous sûre de vouloir supprimer cette carte?") }</div>
                                            <ul>
                                                <li>{entityToDelete.name}</li>
                                            </ul>
                                        </div>}
                                        loading={loadingForm}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default AdminClientMaps
