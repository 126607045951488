import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFileForm from './UploadFileForm';
import { ContainerDetails } from 'backend/ApiAdminDefinition/data-contracts';



interface OwnProps {
    initialValues?: ContainerDetails;
    loading: boolean;
    onSubmit: ((values: PickingConfigurationFormInputs, formikHelpers: FormikHelpers<PickingConfigurationFormInputs>) => void | Promise<any>) & ((values: PickingConfigurationFormInputs, { setFieldError }: FormikHelpers<PickingConfigurationFormInputs>) => void | Promise<void>);
    onCancel: () => void
 }

 export interface PickingConfigurationFormInputs{
    name: string;
    enabled: boolean;
    matrixFile: File;
    matrixComputeFile: File,
    dictionnaryFile: File;
    pointsFile: File;
}


/**
 * Form component for managing picking configuration data.
 * 
 * @param onSubmit Callback function for form submission.
 * @param onCancel Callback function for form cancellation.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @returns The picking configuration form component.
 */
const PickingConfigurationForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({PickingConfigurationForm}), "TransverseError"], { useSuspense: false});

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        matrixFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        matrixComputeFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        //.test('fileFormat', 'PDF only', (value) => {return value && ['application/pdf'].includes(value.type);}),
        dictionnaryFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        pointsFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                        enabled: initialValues?.enabled ?? false,
                        name: initialValues?.enabled ?? "",
                    } as PickingConfigurationFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => {

                        return (
                            <fieldset disabled={loading}>
    
                                    <Row>
                                        <Col>
                                            <Form.Control type="hidden" name="id" value={values.name}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Nom de la configuration")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled={loading}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Activer à la création")}</Form.Label>
                                                <div>
                                                <Switch checked={values.enabled} disabled={isSubmitting} onChange={(enabled) => {
                                                        setFieldValue("enabled", enabled)
                                                    }}/>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.enabled}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                   <Row>
                                    <Col>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label>{t("Fichier matrice de distances")}</Form.Label>
                                            {values.matrixFile ? 
                                            <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("matrixFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.matrixFile.name}</div>: 
                                            <UploadFileForm  loading={loading} onSubmit={(files) => setFieldValue("matrixFile", files[0])}/>}

                                            <Form.Control.Feedback type="invalid">
                                                {errors.matrixFile? t("required", { ns:"TransverseError"}) as string : ""}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                   </Row>

                                   <Row>
                                    <Col>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label>{t("Fichier matrice de calculs")}</Form.Label>
                                            {values.matrixComputeFile ? 
                                            <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("matrixComputeFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.matrixComputeFile.name}</div>: 
                                            <UploadFileForm  loading={loading} onSubmit={(files) => setFieldValue("matrixComputeFile", files[0])}/>}

                                            <Form.Control.Feedback type="invalid">
                                                {errors.matrixComputeFile? t("required", { ns:"TransverseError"}) as string : ""}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                   </Row>
    
                                   <Row>
                                    <Col>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label>{t("Fichier index refs client")}</Form.Label>
                                            {values.dictionnaryFile ?  
                                            <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("dictionnaryFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.dictionnaryFile.name}</div>: 
                                            <UploadFileForm  loading={loading} onSubmit={(files) => setFieldValue("dictionnaryFile", files[0])}/>}
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dictionnaryFile? t("required", { ns:"TransverseError"}) as string : ""}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                   </Row>
    
                                   <Row>
                                    <Col>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label>{t("Fichier vecteurs position 2D")}</Form.Label>
                                            {values.pointsFile ?  
                                            <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("pointsFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.pointsFile.name}</div>: 
                                            <UploadFileForm  loading={loading} onSubmit={(files) => setFieldValue("pointsFile", files[0])}/>}
                                            <Form.Control.Feedback type="invalid">
                                                {errors.pointsFile? t("required", { ns:"TransverseError"}) as string : ""}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                   </Row>

                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno disabled={loading} className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno disabled={loading} className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>

                                    {loading && <div className='d-flex justify-content-center mt-4'>
                                        <h5 className='text-orange'><FontAwesomeIcon icon={['fas', 'warning']} size="sm"/> {t('Cette opération peut prendre plusieurs secondes')}</h5>
                                    </div>}
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default PickingConfigurationForm
