import i18next from "i18next";
import { toast } from "react-toastify";
import { adminMiddlewareRequestActions} from "../middlewareRequestSlice";
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";



export const loadMiddlewareRequestsThunk = (
        baseUrl: string, 
        tenant: string, 
        mapId?: string, 
        sortColumn?: string, 
        sortDirection?: string, 
        page = 1,
        pageSize = 20,
        durationMin = "",
        clientIsBetter = false,
        onlyError = false
      ): AppThunk => {
    return async (dispatch, getState) => {
      try {

        dispatch(adminMiddlewareRequestActions.setLoadingList(true));
        const requests = await apiMiddlewareDefinition(baseUrl, tenant)
                                  .requests.searchRequests({
                                    mapId: mapId,
                                    sortColumn: sortColumn ? _.upperFirst(sortColumn) : undefined,
                                    sortDirection: sortDirection,
                                    page: page,
                                    pageSize: pageSize,
                                    clientIsBetter: clientIsBetter,
                                    durationMin: durationMin,
                                    onlyError: onlyError
                                  });
  

        dispatch(adminMiddlewareRequestActions.setRequests(requests.data));
      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des requêtes d'optimisation"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminMiddlewareRequestActions.setLoadingList(false));
      }
    };
  };