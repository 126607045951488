import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Switch from "react-switch";
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Modal, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import "./AdminClientPickingRunner.scss"
import copyToClipboard from 'utility/copyToClipboard';
import { adminClientPickingActions } from 'store/reducers/Admin/ClientPicking/adminClientPickingSlice';
import PickingRunnerCreateForm, { IPickingRunnerCreateFormInputs } from 'components/Admin/forms/PickingRunnerCreateForm';
import { FormikHelpers } from 'formik';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import useSignalRHub from 'hooks/useSignalRHub';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import { ContainerCreate , ContainerDetails, ContainerUpdate } from 'backend/ApiAdminDefinition/data-contracts';
import { createPickingRunnerThrunks } from 'store/reducers/Admin/ClientPicking/thrunks/createPickingRunnerThrunks';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';
import { loadPickingRunnersThunk } from 'store/reducers/Admin/ClientPicking/thrunks/loadPickingRunnersThunk';
import { toggleEnableRunnerThrunks } from 'store/reducers/Admin/ClientPicking/thrunks/toggleEnableRunnerThrunks';
import { deleteRunnerThrunks } from 'store/reducers/Admin/ClientPicking/thrunks/deleteRunnerThrunks';
import { SpinnerInfinity } from 'spinners-react';
import IRunnerConnectedModel from 'models/Admin/IRunnerConnectedModel';
import StatisticCard from 'components/StatisticCard';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { reconnectRealtimeRunnerThrunk } from 'store/reducers/Admin/ClientPicking/thrunks/reconnectRealtimeRunnerThrunk';
import PickingRunnerUpdateForm, { IPickingRunnerUpdateFormInputs } from 'components/Admin/forms/PickingRunnerUpdateForm';
import { updatePickingRunnerThrunk } from 'store/reducers/Admin/ClientPicking/thrunks/updatePickingRunnerThrunk';
import { restartRunnerThrunk } from 'store/reducers/Admin/ClientPicking/thrunks/restartRunnerThrunk';
import dayjs from 'dayjs';

const AdminClientPickingRunner: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientPickingRunner}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const {
        runners,
        runnersConnected,
        realtimeEnabled, 
        loadingEdit,
        loading,
        selectedRunner
    } = useAppSelector(state => state.adminClientPicking);

    const {
        allClients
    } = useAppSelector(state => state.adminClient);

    const allMaps = _.flatten(allClients.map(c => c.maps))
    const [selectedEntityToDelete, setSelectedEntityToDelete] = useState<ContainerDetails | undefined>();
    const [showCreateRunnerModal, setShowCreateRunnerModal] = useState(false);

    const [realtimeEnable, setRealtimeEnable] = useState(true); // TODO migrate in reducer

    const runnerHub = useSignalRHub(process.env.REACT_APP_API_PICKING_URL + "/live/picking/runner", {
        onConnected: (hub) => {
            setRealtimeEnable(true)

            hub.invoke("GetOnlineRunners").then((runners: IRunnerConnectedModel[]) => {
                console.log("GetOnlineRunners", runners); 
                dispatch(adminClientPickingActions.setConnectedRunners(runners));
            })

            hub.on("RunnerConnected", (runner: IRunnerConnectedModel) => { 
                console.log("RunnerConnected !! ", runner); 
                dispatch(adminClientPickingActions.addOrUpdateConnectedRunner(runner))
            });

            hub.on("RunnerDisconnected", (runner: IRunnerConnectedModel) => { 
                console.log("RunnerDisconnected !! ", runner); 
                dispatch(adminClientPickingActions.removeConnectedRunner(runner))
            });

            hub.on("RunnerUpdated", (runner: IRunnerConnectedModel) => { 
                console.log("RunnerUpdated !! ", runner); 
                dispatch(adminClientPickingActions.addOrUpdateConnectedRunner(runner))
            });
            
        },
        onDisconnected: () => {setRealtimeEnable(false); console.log("runnerHub disconnected");},
        onReconnecting: () => {setRealtimeEnable(false);},
        onError: (err) => {setRealtimeEnable(false); console.log("runnerHub onError !", err);},
        enabled: realtimeEnable,
        automaticReconnect: true,
        httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
      })

      
    useEffect(() => {
        document.title = t('[Admin] Les instances pickings du client');
        dispatch(loadPickingRunnersThunk());
    }, [])


    const createRunner = async (values: IPickingRunnerCreateFormInputs, {setFieldError}:FormikHelpers<IPickingRunnerCreateFormInputs>) => {
        const entity = values as unknown as ContainerCreate ;

        dispatch(createPickingRunnerThrunks(entity,{
            onSuccess: (runner) => {
                toast.success(t("L'instance a bien été crée: ") + " " + runner.name);
                setShowCreateRunnerModal(false);
            } ,
            onError: (error) => {
                parseApiErrorSetFieldError(error, setFieldError);
            }
        }));
    }

    const updateRunner = async (values: IPickingRunnerUpdateFormInputs, {setFieldError}:FormikHelpers<IPickingRunnerUpdateFormInputs>) => {
        const entity = values as unknown as ContainerUpdate;

        dispatch(updatePickingRunnerThrunk(entity,{
            onSuccess: (runner) => {
                toast.success(t("L'instance a bien été modifiée: ") + " " + runner.name);
                dispatch(adminClientPickingActions.setSelectedRunner(undefined))
            } ,
            onError: (error) => {
                parseApiErrorSetFieldError(error, setFieldError);
            }
        }));
    }

    const enableRunner = (runnerToUpdate: ContainerDetails, enabled: boolean) => {

        // update local state before API result for simulate realtime update and force UI on/off button to change state when user click
        dispatch(adminClientPickingActions.updateRunner({
            ...runnerToUpdate,
            enabled: enabled
        }))


        dispatch(toggleEnableRunnerThrunks(runnerToUpdate.id, enabled, {
            onSuccess: (runner) => {
                toast.success(runner.enabled ? t("L'instance a bien été activée"): t("L'instance a bien été désactivée"))
            },
            onError: (error) => {
                toast.error(t("Une erreur s'est produite"));
                // reverte change if n error occurs
                dispatch(adminClientPickingActions.updateRunner({
                    ...runnerToUpdate,
                    enabled: !enabled
                }))
            }
        }))
    }


    
    const onDeleteEntity = (runner: ContainerDetails) => {
        dispatch(deleteRunnerThrunks(runner.id, {
            onSuccess: (success) => {
                setSelectedEntityToDelete(undefined);
                toast.success(t("L'instance a bien été supprimée"));
            },
            onError: (error) => {
                parseApiErrorSetFieldError(error, (field, msg) => toast.error(`${field}: ${t(msg ?? "", { ns:"TransverseError"})}`));
            }
        }))    
    }

    const onRestartEntity = (runner: ContainerDetails) => {
        dispatch(restartRunnerThrunk(runner, {
            onSuccess: (success) => {
            },
            onError: (error) => {
            }
        }))    
    }



    const getColumns = () => {
        const columns: TableColumn<ContainerDetails>[] = [
            {
                id:'id',
                name: t('Id'),
                sortable: true,
                width: "80px",
                selector: row => row.id,
                cell: (row, index, column, id) => {
                    return <div>
                        <Button size='sm' variant='link' onClick={() => copyToClipboard(row.id, t("L'id a bien été copié"))} title={t("copier l'id") + " " + row.id}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
                    </div>
                }
            },
            {
                id:'online',
                name: <FontAwesomeIcon icon={["fas", "wifi"]} />,
                width:"80px",
                sortable: true,
                selector: row => runnersConnected.find(m => m.runnerId == row.id) ? true : false,
                cell: row => {
                    const connected = runnersConnected.find(m => m.runnerId == row.id);
    
                    let btnReconnect = <></>
                    if(!connected && realtimeEnable)
                    {
                        btnReconnect = <ButtonFno className='ms-1' sm onClick={() => dispatch(reconnectRealtimeRunnerThrunk(row.id))}><FontAwesomeIcon icon={faSyncAlt} /></ButtonFno>
                    }
    
                    let onlineBadge = <Badge bg={!connected ? "danger": "success"}> {!connected ? "Non": "Oui"}</Badge>;
                    if(!realtimeEnable)
                        onlineBadge = <Badge bg={"secondary"}> {"?"}</Badge>;
    
                    return <>
                        {onlineBadge}
                        {btnReconnect}
                    </>
                },
            },
            {
                id:'status',
                name: t('Statut'),
                width:"150px",
                sortable: true,
                cell: row => {
                    const connected = runnersConnected.find(m => m.runnerId == row.id);
                    if(!connected) return <></>

                    let color = "secondary";
                    let text = <><FontAwesomeIcon icon={["fas", "circle-question"]} /> {t("Inconnu")}</>;


                    const nbRequests = connected.currentRequestsCounter ;
                    const state = connected.state;

                    if(!realtimeEnable)
                    {
                        color = "secondary";
                        text = <><FontAwesomeIcon icon={["fas", "circle-question"]} /> {t("Inconnu")}</>; 
                    }
                    else if(state == "preloadMap")
                    {
                        color = "warning";
                        text = <span className='text-black'><Loading inline color='black' size={15}/> {t("Chargement d'une map")}</span> 
                    }
                    else if(nbRequests == 0)
                    {
                        color = "success";
                        text = <><FontAwesomeIcon icon={["fas", "check"]} /> {t("Libre")}</> 
                    }
                    else if(nbRequests > 0){
                        color = "primary";
                        text = <><Loading inline color='white' size={15}/> {`${t("Optimisation")}`}</> 
                    }
                    else{
                        color = "secondary";
                        text = <><FontAwesomeIcon icon={["fas", "circle-question"]} /> {nbRequests}</>;
                    }



                    return <Badge bg={color}> {text}</Badge>
                    
                },
            },
            {
                id:'requests',
                name: t('Requêtes'),	
                width:"150px",
                sortable: true,
                cell: row => {
                    const connected = runnersConnected.find(m => m.runnerId == row.id);
                    if (!connected) return <></>

                    const nbRequests = connected.currentRequestsCounter;
                    const color = nbRequests == 0 ? "success" : (nbRequests > 0 && nbRequests < 5 ? "primary" : "warning");
                    const requests = `${nbRequests}`;
                    return <Badge bg={color}>{requests}</Badge>
                },

            },
            {
                id:'container',
                name: t('Container'),
                sortable: true,
                selector: row => row.container
            },
            {
                id:'name',
                name: t('Nom'),
                sortable: true,
                selector: row => row.name
            },
            /*{
                id:'image',
                name: t('Image'),
                sortable: true,
                selector: row => row.image
            },*/
            {
                id:'config',
                name: t('config'),
                sortable: true,
                cell: row => <div>
                    <div>{`${t('CPU')} : ${row.cpu}`}</div>
                    <div>{`${t('RAM')} : ${row.memoryInGB} ${t('GB')}`}</div>
                </div>
            },
            {
                id:'url',
                name: t('url'),
                width:"220px",
                sortable: false,
                cell:(row, index, column, id) => {

                    if(!row.ip) return <></>

                    const url = `${row.ip}:${row.port}`
                    return <>
                        <a className='me-2' href={`http://${url}`} target='_blank'>{url}</a>
                        <Button size="sm" variant='link' onClick={() => copyToClipboard(url, "Adresse copiée")} title={t("Copier l'adresse dans le presse papier")}><FontAwesomeIcon icon={['fas', 'copy']} size="sm"/></Button>
                    </>
                }
            },
            {
                id:'preloadMaps',
                name: t('Cartes'),
                sortable: true,
                selector: row => runnersConnected.find(m => m.runnerId == row.id)?.preloadMaps.length ?? 0,
                cell:(row, index, column, id) => {

                    if(row.isGlobalRunner)
                    {
                        return <Badge bg={"primary"}>{t("Global")}</Badge>
                    }


                    const allPreloadMaps = runnersConnected.find(m => m.runnerId == row.id)?.preloadMaps ?? [];
                    return <>
                        {row.mapsPreloaded && row.mapsPreloaded.map(map => <Badge 
                                                        key={map.mapId} 
                                                        title={allPreloadMaps.some(m => m.mapId == map.mapId) ? t("Carte chargée") : t("Carte non chargée")}
                                                        bg={allPreloadMaps.some(m => m.mapId == map.mapId) ? "success" : "danger"}>
                                                            {allPreloadMaps.some(m => m.mapId == map.mapId) ? <FontAwesomeIcon icon={['fas', 'check']} size="sm"/> : <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} size="sm"/>}
                                                            {" " + allMaps.find(m => m.id == map.mapId)?.name}
                                                            </Badge>)}
                    </>
                }
            },
            {
                id:'enabled',
                name: t('Active'),
                sortable: true,
                center: true,
                width:"100px",
                selector: row => row.enabled,
                cell:(row, index, column, id) => {
                    return <><Switch checked={row.enabled ? row.enabled : false} onChange={(enabled) => enableRunner(row, enabled)}/></> 
                }
            },
            {
                id:'dateLastRestart',
                name: t('Dernier restart'),
                sortable: true,
                center: true,
                selector: row => `${row.dateLastRestart ? dayjs(row.dateLastRestart).unix(): ""}`,
                cell: row => `${row.dateLastRestart ? dayjs(row.dateLastRestart).format("DD/MM/YYYY HH:mm"): ""}`
            },
            {
                name:<div>{t('Actions')}</div>,
                center:true,
                width:"150px",
                cell: (row, index, column, id) =>{
                    return <div>
                        <Button size='sm' variant='primary' title={t("Redemarrer")} onClick={() => onRestartEntity(row)}><FontAwesomeIcon icon={["fas", "arrows-rotate"]} /> </Button>
                        <Button size='sm' variant='success' title={t("Modifier")} className='ms-1' disabled={!runners.some(r => r.id == row.id)} onClick={() => dispatch(adminClientPickingActions.setSelectedRunner(row))}><FontAwesomeIcon icon={["fas", "edit"]} /> </Button>
                        <Button size='sm' variant='danger' title={t("Supprimer")} className='ms-1' onClick={() => setSelectedEntityToDelete(row)}><FontAwesomeIcon icon={["fas", "trash"]} /> </Button>
                    </div>
                }
            }
        ];

        return columns;
    };


    const onHideModal = () => {
        setShowCreateRunnerModal(false);
    }


    // Manage connected runner that not exist in database
    const runnerNotExistDb = _.differenceWith(runnersConnected, runners, (connected, runner) => runner.id == connected.runnerId).map((runnerConnected) => {
        return {
            id: runnerConnected.runnerId,
            name: "N/C",
            container: "N/C",
            image: "N/C",
            memoryInGB:0
        } as ContainerDetails;
    });
    
    const allRunners = [...runners, ...runnerNotExistDb];

    let countOffline = runners.length - runnersConnected.length
    if(countOffline <  0) countOffline = 0;

    let utilisationPercent = (_.filter(runnersConnected, (connected) => connected.currentRequestsCounter > 0).length / runnersConnected.length) * 100
    if(!utilisationPercent) utilisationPercent = 0;

    return (
        <div id="admin-client-picking-runner">
             <PageHeader title={t("Runners")} admin/>

             <Row>
                <Col><StatisticCard color='blue' title={t("Total Runners")} icon='server' value={runners.length}/></Col>
                <Col><StatisticCard color={utilisationPercent > 50 ? 'red' : 'green'} title={t("Taux utilisation")} icon='percent' value={Math.ceil(utilisationPercent) + " %"}/></Col>
                <Col><StatisticCard color={countOffline > 0 ? 'red' : 'green'} title={t("Runners offline")} icon={countOffline > 0 ? 'circle-exclamation' : 'circle-check'} value={countOffline}/></Col>
            </Row>

            <div>
                <div className='table-component'>
                    <DataTable 
                        columns={getColumns()} 
                        data={allRunners} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        defaultSortFieldId={'online'} 
                        defaultSortAsc={false} 
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune instance disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Instances de VM d'optimisation picking") + " ("+runners.length+")"} {loading && <Loading inline size={20}/>}</h5>
                            <div className='search-panel'>
                                <span className='me-2'>
                                    {realtimeEnable && <SpinnerInfinity size={50} thickness={180} speed={98} color="rgba(62, 172, 57, 1)" secondaryColor="rgba(172, 102, 57, 0.31)" />}
                                </span>
                                <span className='me-2'>  
                                    <Switch checked={realtimeEnable} onChange={(enabled) => setRealtimeEnable(enabled)}/>
                                </span>
                                <ButtonFno sm className='me-2' onClick={() => setShowCreateRunnerModal(true)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Nouvelle instance")}</ButtonFno>
                                {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                            </div>
                            </>}
                        sortServer={false}/>
                </div>
            </div>


            {(showCreateRunnerModal) && <Modal dialogClassName='modal-fno' show={true} onHide={onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer une nouvelle instance")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PickingRunnerCreateForm onCancel={onHideModal} onSubmit={createRunner} initialValues={{
                        
                    } as ContainerDetails} loading={loadingEdit}/>
                </Modal.Body>
            </Modal>}

            {selectedEntityToDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedEntityToDelete(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Supprimer l'instance") + " " + selectedEntityToDelete.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={() => setSelectedEntityToDelete(undefined)} 
                                        onSubmit={() => onDeleteEntity(selectedEntityToDelete)} 
                                        text={<div>
                                            <div>{t("Êtes vous sûre de vouloir supprimer cette instance ?") }</div>
                                            <div><h5 className='text-orange'><FontAwesomeIcon icon={['fas', 'warning']} size="sm"/> {t('Cette opération peut prendre plusieurs minutes')}</h5></div>
                                        </div>}
                                        loading={loadingEdit}/>
                </Modal.Body>
            </Modal>}

            <Offcanvas className="offcanvas-request-details" show={!!selectedRunner} onHide={() => dispatch(adminClientPickingActions.setSelectedRunner(undefined))} >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{selectedRunner?.name}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <PickingRunnerUpdateForm 
                                onCancel={() => dispatch(adminClientPickingActions.setSelectedRunner(undefined))} 
                                onSubmit={updateRunner} 
                                loading={loadingEdit}
                                initialValues={selectedRunner} />
                </Offcanvas.Body>
        </Offcanvas>
        </div>
    )
}

export default AdminClientPickingRunner
