import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import Switch from "react-switch";
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptimizationSettingDetails } from "backend/ApiAdminDefinition/data-contracts";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PickingConfigurationForm, { PickingConfigurationFormInputs } from 'components/Admin/forms/PickingConfigurationForm';
import { FormikHelpers } from 'formik';
import { downloadFile } from 'utility/downloadFile';
import { createAndUploadOptimizationSettingFilesThunk } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/createAndUploadOptimizationSettingFilesThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { loadOptimizationSettingThunks } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/loadOptimizationSettingThunks';
import { enableOptimizationSettingThrunks } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/enableOptimizationSettingThrunks';
import { deleteOptimizationSettingThunk } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/deleteOptimizationSettingThunk';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapPickingSetting: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapPickingSetting}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const { clientId, mapId } = useParams();

    const {
        loadingList,
        loadingForm,
        optimizationSettings
    } = useAppSelector(state => state.adminOptimizationSetting);

    useEffect(() => {
        document.title = t('[Admin] Les configuration picking');
        if(clientId && mapId && mapId != "new")
        {
            loadSettings();
        }
    }, [mapId])


    const loadSettings = () => {
        if(clientId && mapId)
        {
            dispatch(loadOptimizationSettingThunks(mapId));
        }   
    }


    const onCreate = (values: PickingConfigurationFormInputs, {setFieldError}:FormikHelpers<PickingConfigurationFormInputs>) => {
        if(clientId && mapId)
        {
            dispatch(createAndUploadOptimizationSettingFilesThunk(mapId, values,
                {
                    dictionnaryFile: values.dictionnaryFile,
                    matrixComputeFile: values.matrixComputeFile,
                    matrixFile: values.matrixFile,
                    pointsFile: values.pointsFile
                },
                {
                onSuccess: (marker) => {
                    setShowCreateModal(false);
                    toast.success(t("La configuration a bien été créée"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onEnabledSetting = (setting: OptimizationSettingDetails) => {
        if(clientId && mapId && setting.enabled == false)
        {

            dispatch(enableOptimizationSettingThrunks(mapId, setting.id, {
                onSuccess: (settingUpdated) => {
                    toast.success(t('La configuration a bien été activée'))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }));
        }
        
    }

    const onDelete = (setting: OptimizationSettingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteOptimizationSettingThunk(mapId, setting.id, {
                onSuccess: (result) => {
                    toast.success(t('La configuration a bien été supprimée'))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }));
        }
    }

    const columns: TableColumn<OptimizationSettingDetails>[] = [
        {
            id:'enabled',
            name: t('Active'),
            sortable: true,
            width:"110px",
            selector: row => row.enabled,
            cell: row =>  <Switch checked={row.enabled} onChange={(enabled) => {onEnabledSetting(row)}}/>
        },
        {
            id:'version',
            name: t('Version'),
            sortable: true,
            width:"110px",
            selector: row => row.idShort,
        },
        {
            id:'name',
            name: t('name'),
            sortable: true,
            selector: row => row.name,
        },
        {           
            name:<div className='text-center'>{t('Matrice de distances')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlMatrix && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlMatrix, "matrix_optimization.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Matrice de calculs')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlComputeMatrix && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlComputeMatrix, "matrix_compute.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Refs client')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlIndexes && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlIndexes, "indexes.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Vecteurs position 2D')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlPoints && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlPoints, "2Dpoints.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {
            id:'author',
            name: t('Auteur'),
            sortable: true,
            selector: row => row.author
        },
        {
            id:'dateCreated',
            name: t('Créé le'),
            sortable: true,
            selector: row => `${row.dateCreated ? dayjs(row.dateCreated).unix(): ""}`,
            cell: row => `${row.dateCreated ? dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm"): ""}`
        }
    ];


    return <>
    <TableWithCrudComponent 
        entities={optimizationSettings} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onClickCreateButton={() => setShowCreateModal(true)}
        translations={{
            tableTitle: t("Configuration de la carte"),
            noEntityText: t("Aucune configuration disponible"),
            createTitle: t("Ajouter une configuration"),
            createButtonText: t("Ajouter une configuration"),
            deleteText: (entity) => (t("Voulez-vous supprimer la configuration ?")),
            deleteTitle: (entity) => (t("Supprimer la configuration ") + entity.name),
            updateText: (entity) => (t("Modifier la configuration ")  + entity.name),
        }}
        fieldSearchable={(entity) => [
            entity.idShort,
            entity.id,
            entity.author,
            entity.name
        ]}
        />
           {(showCreateModal) && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer une nouvelle configuration picking")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PickingConfigurationForm onCancel={() => setShowCreateModal(false)} onSubmit={onCreate} loading={loadingForm}/>
                </Modal.Body>
            </Modal>}
        </>
}

export default AdminClientMapPickingSetting
