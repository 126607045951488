import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { middlewarePickingActions } from "store/reducers/MiddlewarePicking/middlewarePickingSlice";

export const loadMiddlewareThunk = (): AppThunk => {
    return async (dispatch, getState) => {

        try{
            const allMiddlewares = await apiBackofficeDefinition.middleware.getAllClientMiddlewares();

            dispatch(middlewarePickingActions.setMiddlewares(allMiddlewares.data))

            // select first middleware by default
            if(allMiddlewares.data.length > 0)
            {
                dispatch(middlewarePickingActions.setMiddlewareSelected(allMiddlewares.data[0]));
            }
            else{
                dispatch(middlewarePickingActions.setMiddlewareSelected(undefined));
            }
        }
        catch(error: any)
        {
            console.error("[selectTenantThunk] error", error)
            dispatch(userProfilActions.setLoadingUserData(false));
        }
    }
}