/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Article {
    ean?: string | null
    address?: string | null
    productFamily?: string | null
    /** @format double */
    quantityToPrepare?: number
    isPromotional?: boolean
    mission?: string | null
}

export interface ErrorsReturn {
    errors?: string[] | null
}

export interface MiddlewareStatusResult {
    realtimeOnline?: boolean
}

export interface OptimizationBatchDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    requestClientId: string
    /** @minLength 1 */
    optimizationType: string
    optimizePathDetailsIds: string[]
    finalResultPathDetailsIds: string[]
    clientPathDetailsIds: string[]
    /** @format date-span */
    processDuration: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export enum PathDetailsBatchField {
    OptimizePathDetails = 'OptimizePathDetails',
    FinalResult = 'FinalResult',
    ClientPathDetails = 'ClientPathDetails'
}

export interface PathDetailsModel {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    optimizationBatchId: string
    batchField: PathDetailsBatchField
    /** @format date-time */
    dateRequestClient: string
    /** @minLength 1 */
    mission: string
    processedProductIds: string[]
    /** @format double */
    distance: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface PathDetailsModelPagedListResult {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    items: PathDetailsModel[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

export interface ProcessedProductDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    optimizationBatchId: string
    /** @minLength 1 */
    requestRawProductId: string
    /** @minLength 1 */
    requestClientId: string
    /** @minLength 1 */
    address: string
    /** @minLength 1 */
    mission: string
    /** @minLength 1 */
    support: string
    /** @format double */
    quantity: number
    /** @format double */
    weight: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @minLength 1 */
    error: string
}

export interface RealtimeReconnectResult {
    connected?: boolean
}

export interface RequestClientDetails {
    /** @minLength 1 */
    id: string
    rawData: any
    resultData: any
    /** @minLength 1 */
    mapId: string
    bestBatchIds: string[]
    logs: RequestStageDetails[]
    /** @minLength 1 */
    status: string
    /** @format date-span */
    duration?: string | null
    /** @format int32 */
    totalProducts: number
    /** @format int32 */
    totalMissions: number
    /** @format double */
    totalOriginalDistance: number
    /** @format double */
    totalOptimizedDistance: number
    /** @format double */
    totalPathDiff: number
    headers: StringStringKeyValuePair[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format double */
    durationMs: number
}

export interface RequestClientDetailsPagedListResult {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    items: RequestClientDetails[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

export interface RequestClientModel {
    storeId?: string | null
    deviceId?: string | null
    sessionId?: string | null
    articles?: Article[] | null
    isOptimized?: boolean
    isError?: boolean
}

export interface RequestRawProductDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    requestClientId: string
    /** @format date-time */
    dateLimit: string
    /** @format date-time */
    dateRequestClient: string
    resultOptimizationBatchIds: string[]
    /** @minLength 1 */
    bestBatchId: string
    rawData: any
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface RequestRawProductDetailsPagedListResult {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    items: RequestRawProductDetails[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

export interface RequestStageDetails {
    /** @format date-time */
    date: string
    /** @minLength 1 */
    stageType: string
    /** @minLength 1 */
    message: string
    /** @format double */
    duration: number
    isError: boolean
}

export interface StatisticsByDay {
    id: string | null
    /** @format date-time */
    date: string
    mapId: string | null
    /** @format int32 */
    totalRequests?: number
    /** @format int32 */
    totalRequestErrors?: number
    /** @format int32 */
    totalRequestOptimized?: number
    /** @format int32 */
    totalProducts?: number
    /** @format double */
    totalQuantity?: number
    /** @format int32 */
    totalMissions?: number
    /** @format double */
    totalOriginalDistance?: number
    /** @format double */
    totalOptimizedDistance?: number
    pickerTotal?: Record<string, number>
    unknowAddressTotal?: Record<string, number>
}

export interface StringStringKeyValuePair {
    key?: string | null
    value?: string | null
}
