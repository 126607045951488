import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "components/Loading";
import i18next from "i18next";
import { toast } from "react-toastify";

/**
 * Download a file from an external URL and save it in the local download folder
 * @param url 
 * @param fileName 
 */
export async function downloadFile(url : string, fileName: string){
    const toastId = toast.info(<div>
        {i18next.t("Téléchargement du fichier en cours")}
        <ul>
            <li><b>{fileName}</b></li>
        </ul>
        <div className="text-center"><Loading inline size={30} /></div>
    </div>, { autoClose: false });


    const response = await fetch(url);

    const blobImage = await response.blob();


    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = fileName;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
    toast.update(toastId, { render: <div>
        <FontAwesomeIcon size="lg" icon={["fas", "check"]} /> {i18next.t("Téléchargement terminé")}
    </div>, autoClose: 5000, type: "success" });
    
}